"use client";
import Link from "next/link";

import { login } from "@/app/actions/auth/actions";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNotificationDispatch } from "@/lib/hooks/use-notification";
import { createClient } from "@/lib/supabase/client";
import { Flex, Grid, Separator } from "@radix-ui/themes";
import { random } from "lodash";
import { CircleAlert, Info, Loader2 } from "lucide-react";
import { useRouter, useSearchParams } from "next/navigation";
import { useActionState, useState } from "react";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "../ui/input-otp";

type Response = {
  error?: string;
  user?: any;
  message?: {
    msg: string;
    title: string;
  };
};

export default function LoginForm() {
  const supabase = createClient();
  const router = useRouter();
  const searchParams = useSearchParams();

  const [otpPrompt, setOtpPrompt] = useState(false);
  const [otpVal, setOtpVal] = useState("");
  const [otpFactor, setOtpFactor] = useState<string>();

  const email_verified = searchParams.get("email_verified");
  const dispatchNotification = useNotificationDispatch();
  const [loginState, loginSubmitAction, loginIsPending] = useActionState(
    async (previousState: any, formData: FormData) => {
      const email = formData.get("email")?.valueOf() as string;
      const password = formData.get("password")?.valueOf() as string;

      if (!email || !password) {
        return { error: "Email and password are required" } as Response;
      }

      const { error, otp_required, factorId } = await login(formData);
      if (error) {
        console.log(error);
        return { error: error } as Response;
      }

      if (otp_required) {
        console.log("OTP required", factorId);
        setOtpFactor(factorId);
        console.log("wait", otpFactor);
        setOtpPrompt(true);

        return null;
      }

      dispatchNotification({
        type: "SEND_NOTIFICATION",
        notification: {
          id: random().toString(),
          title: "Logged in",
          message: "Welcome back!",
          type: "success",
          sent: false,
          read: false,
        },
      });

      router.push("/dashboard");

      return null;
    },
    null,
  );

  const [signupState, signupSubmitAction, signupIsPending] = useActionState(
    async (previousState: any, formData: FormData) => {
      const email = formData.get("email")?.valueOf() as string;
      const password = formData.get("password")?.valueOf() as string;

      if (!email || !password) {
        return { error: "Email and password are required" } as Response;
      }

      const supabase = createClient();

      const { data, error } = await supabase.auth.signUp({
        email: email,
        password: password,
      });

      if (error) {
        return { error: error.message } as Response;
      }

      const { user, session } = data;
      console.log("CAKE", user, session);

      if (!user && !session) {
        return { error: "An error occurred" } as Response;
      }

      if (user && !session) {
        return {
          message: {
            msg: "Check your email and follow the link",
            title: "Email verification required",
          },
        } as Response;
      }

      if (user && session) {
        // user is logged in
        router.push("/dashboard");
      }
    },
    null,
  );

  const attemptVerifyCode = async () => {
    (async () => {
      if (!otpFactor) {
        console.log("No factor");
        return;
      }

      const challenge = await supabase.auth.mfa.challenge({
        factorId: otpFactor,
      });
      if (challenge.error) {
        throw challenge.error;
      }

      console.log("cake", challenge);

      const challengeId = challenge.data.id;

      const verify = await supabase.auth.mfa.verify({
        factorId: otpFactor,
        challengeId,
        code: otpVal,
      });
      if (verify.error) {
        throw verify.error;
      }

      if (verify.data.access_token) {
        dispatchNotification({
          type: "SEND_NOTIFICATION",
          notification: {
            id: random().toString(),
            title: "Logged in",
            message: "Welcome back!",
            type: "success",
            sent: false,
            read: false,
          },
        });

        router.push("/dashboard");
      }
      setOtpPrompt(false);
      console.log(verify);
    })();
  };

  return (
    <Card className="sm:max-w-[425px] w-full">
      <CardHeader>
        <CardTitle>Sign in</CardTitle>
        <CardDescription>
          Enter your email below or sign in with Google or GitHub
        </CardDescription>
      </CardHeader>

      <CardContent>
        <div className="mx-auto grid w-full gap-6">
          <div className="grid gap-4">
            <form className="grid gap-4">
              {(signupState?.error || loginState?.error) && (
                <Alert variant="destructive">
                  <CircleAlert className="h-4 w-4" />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>
                    {signupState?.error || loginState?.error}
                  </AlertDescription>
                </Alert>
              )}
              {(signupState?.message || loginState?.message) && (
                <Alert>
                  <Info className="h-4 w-4" />
                  <AlertTitle>
                    {signupState?.message?.title || loginState?.message?.title}
                  </AlertTitle>
                  <AlertDescription>
                    {signupState?.message?.msg || loginState?.message?.msg}
                  </AlertDescription>
                </Alert>
              )}
              {email_verified && (
                <Alert>
                  <Info className="h-4 w-4" />
                  <AlertTitle>Email verified</AlertTitle>
                  <AlertDescription>You can now login below!</AlertDescription>
                </Alert>
              )}
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="m@example.com"
                  required
                />
              </div>
              <div className="grid gap-2">
                <div className="flex items-center">
                  <Label htmlFor="password">Password</Label>
                  {/* <Link
                    href="/forgot-password"
                    className="ml-auto inline-block text-sm underline"
                  >
                    Forgot your password?
                  </Link> */}
                </div>
                <Input id="password" type="password" name="password" required />
              </div>
              <Button
                disabled={loginIsPending || signupIsPending}
                type="submit"
                className="w-full"
                formAction={loginSubmitAction}
              >
                {(loginIsPending || signupIsPending) && (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                )}
                Login
              </Button>
            </form>
            <Grid columns="1fr 40px 1fr" align="center">
              <Separator className="w-full" size="4" />
              <div className="text-center text-gray-500">or</div>
              <Separator className="w-full" size="4" />
            </Grid>
            <Link href="/auth/oauth/google">
              <Button variant="outline" className="w-full">
                Login with Google
              </Button>
            </Link>
            <Link href="/auth/oauth/github">
              <Button variant="outline" className="w-full">
                Login with Github
              </Button>
            </Link>
          </div>
          <div className="text-center text-sm mt-4">
            Don&apos;t have an account?{" "}
            <Link href="/signup" className="underline">
              Sign up
            </Link>
          </div>
        </div>
        <Dialog open={otpPrompt} onOpenChange={setOtpPrompt}>
          <DialogContent className="max-w-sm !justify-center">
            <DialogHeader>
              <DialogTitle className="text-center">
                Two-factor authentication
              </DialogTitle>
              <DialogDescription className="text-center">
                Please enter the code from your authenticator app
              </DialogDescription>
            </DialogHeader>
            <Flex justify="center" className=" w-full">
              <InputOTP
                maxLength={6}
                value={otpVal}
                onChange={(value) => setOtpVal(value)}
              >
                <InputOTPGroup>
                  <InputOTPSlot index={0} />
                  <InputOTPSlot index={1} />
                  <InputOTPSlot index={2} />
                </InputOTPGroup>
                <InputOTPSeparator />
                <InputOTPGroup>
                  <InputOTPSlot index={3} />
                  <InputOTPSlot index={4} />
                  <InputOTPSlot index={5} />
                </InputOTPGroup>
              </InputOTP>
            </Flex>
            <DialogFooter className="flex !justify-center">
              <Button variant="secondary" onClick={() => setOtpPrompt(false)}>
                Cancel
              </Button>
              <Button variant="default" onClick={() => attemptVerifyCode()}>
                Submit
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
}
