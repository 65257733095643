"use client";
import LoginForm from "@/components/auth/LoginForm";
import { useTheme } from "next-themes";
import Image from "next/image";
import { Suspense } from "react";

export default function Page() {
  const { resolvedTheme } = useTheme();
  return (
    <div className="w-full grid min-h-[100vh] grid-cols-1 justify-center align-center items-center justify-items-center content-center">
      <div className="w-full sm:max-w-[425px] items-center justify-center text-center relative mb-8 min-h-24 flex">
        <Image
          src={
            resolvedTheme === "dark"
              ? "/images/tixi-logo-hor-colour.dark.min.svg"
              : "/images/tixi-logo-hor-colour.min.svg"
          }
          alt="tixi Logo"
          width={110}
          height={40}
        />
      </div>
      <Suspense>
        <LoginForm />
      </Suspense>
    </div>
  );
}
