import { useContext } from "react";
import {
  NotificationContext,
  NotificationDispatchContext,
} from "../context/NotificationContext";

export function useNotification() {
  return useContext(NotificationContext);
}
export function useNotificationDispatch() {
  return useContext(NotificationDispatchContext);
}

// const handleInserts = (payload) => {
//   console.log("Change received!", payload);
// };

// const startNotificationStream = async () => {
//   const supabase = createClient();
//   // Listen to inserts
//   supabase
//     .channel("notifications")
//     .on(
//       "postgres_changes",
//       { event: "INSERT", schema: "public", table: "notifications" },
//       handleInserts,
//     )
//     .subscribe();
// };
