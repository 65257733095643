
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"270a291108f2dcf3cf86b68442887352806f6667":"login"} */ export var login = createServerReference("270a291108f2dcf3cf86b68442887352806f6667");
import { createServerReference } from "private-next-rsc-action-client-wrapper";

