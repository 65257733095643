import {
  type NotificationActions,
  type NotificationState,
} from "@/types/notification";
import { Dispatch, createContext } from "react";

export const initialState: NotificationState = {
  notifications: [],
  channel: null,
};

export const NotificationContext =
  createContext<NotificationState>(initialState);
export const NotificationDispatchContext = createContext<
  Dispatch<NotificationActions>
>(() => {});
