
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"bec2018a38db4c2bbbdba3ac12846becb24026f3":"unlinkProvider","c51cd10be7d5ba7da0dfd48e8fea9b5bcfe2f81d":"signup"} */ export var unlinkProvider = createServerReference("bec2018a38db4c2bbbdba3ac12846becb24026f3");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var signup = createServerReference("c51cd10be7d5ba7da0dfd48e8fea9b5bcfe2f81d");

