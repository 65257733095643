
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"cf6a197c3ef97c77dcb509d4a0ffdff9832d3c20":"logout"} */ export var logout = createServerReference("cf6a197c3ef97c77dcb509d4a0ffdff9832d3c20");
import { createServerReference } from "private-next-rsc-action-client-wrapper";

